$(document).foundation();

$(document).ready(function() {
	var size_h1 = jQuery("h1").css('font-size');
	var line_height_h1 = jQuery("h1").css('line-height');
	document.getElementById("h1-font-size").innerHTML = size_h1;
	document.getElementById("h1-line-height").innerHTML = line_height_h1;
	var size_h2 = jQuery("h2").css('font-size');
	var line_height_h2 = jQuery("h2").css('line-height');
	document.getElementById("h2-font-size").innerHTML = size_h2;
	document.getElementById("h2-line-height").innerHTML = line_height_h2;
	var size_h3 = jQuery("h3").css('font-size');
	var line_height_h3 = jQuery("h3").css('line-height');
	document.getElementById("h3-font-size").innerHTML = size_h3;
	document.getElementById("h3-line-height").innerHTML = line_height_h3;
	var size_h4 = jQuery("h4").css('font-size');
	var line_height_h4 = jQuery("h4").css('line-height');
	document.getElementById("h4-font-size").innerHTML = size_h4;
	document.getElementById("h4-line-height").innerHTML = line_height_h4;
	var size_h5 = jQuery("h5").css('font-size');
	var line_height_h5 = jQuery("h5").css('line-height');
	document.getElementById("h5-font-size").innerHTML = size_h5;
	document.getElementById("h5-line-height").innerHTML = line_height_h5;
	var size_h6 = jQuery("h6").css('font-size');
	var line_height_h6 = jQuery("h6").css('line-height');
	document.getElementById("h6-font-size").innerHTML = size_h6;
	document.getElementById("h6-line-height").innerHTML = line_height_h6;
	var size_lead = jQuery("p.lead").css('font-size');
	var line_height_lead = jQuery("p.lead").css('line-height');
	document.getElementById("lead-font-size").innerHTML = size_lead;
	document.getElementById("lead-line-height").innerHTML = line_height_lead;
	
	function rgb2hex(rgb){
		console.log(rgb);
		 rgb = rgb.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)/);
		 console.log(rgb);
		 return "#" +
		  ("0" + parseInt(rgb[1],10).toString(16)).slice(-2) +
		  ("0" + parseInt(rgb[2],10).toString(16)).slice(-2) +
		  ("0" + parseInt(rgb[3],10).toString(16)).slice(-2);
	}
		
	$('.get-bg-color').each(function() {
		var color = $(this).css('background-color');
		console.log(color);
		var hex = rgb2hex(color);
		$(this).find('.display').text(hex + ' / ' + color);
	});
});